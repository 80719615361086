import github from "../img/github00.png";
import "../styles/footer.css";

export const Footer = () => (
  <footer className="barra-inferior">
    <p>
      Developed by <img className="img-footer" src={github} width="30px" height="30px" />{" "}
      <a href="https://github.com/adaschuler">Ada Schüler</a>,<br></br> with
      love!
    </p>
  </footer>
);
