import React from "react";
// import { useHistory } from "react-router-dom";
// import { Link } from "react-router-dom";

import { Footer } from "./component/footer";
import { Navbar } from "./component/navbar";
import Card from "react-bootstrap/Card";

import "./App.css";

import IMG01 from "./img/img01.png";
import IMG02 from "./img/img02.png";
import IMG03 from "./img/img03.png";

const CONTENT_CARDS = [
  {
    title: "Tiendas de joyas",
    links: [
      {
        name: "ASV Jewels",
        link: "https://asv-jewels.com/",
      },
      {
        name: "Inner Magnetar",
        link: "https://asv-jewels.com/inner-magnetar-by-asv/",
      },
    ],
    img: IMG02,
  },
  {
    title: "Trabajo",
    links: [
      {
        name: "LinkedIn",
        link: "https://www.linkedin.com/in/adaschuler/",
      },
      {
        name: "GITHUB",
        link: "https://github.com/adaschuler",
      },
      // {
      //   name: "Clases online Arquitectura",
      //   link: "https://www.superprof.cl/arquitecta-con-anos-experiencia-clases-online-para-estudiantes-arquitectura-revit-recomendaciones-empleabilidad.html",
      // },
    ],
    img: IMG01,
  },
  {
    title: "Bienestar",
    links: [
      {
        name: "Yoga",
        link: "https://clases-de-yoga-online.vercel.app/",
      },
      {
        name: "Oráculo virtual",
        link: "https://clases-de-yoga-online.vercel.app/recursos",
      },
    ],
    img: IMG03,
  },
  {
    title: "Artículos",
    links: [
      {
        name: "La Inteligencia Artificial y su lugar en un mundo de creatividad humana",
        link: "https://www.linkedin.com/pulse/la-inteligencia-artificial-y-su-lugar-en-un-mundo-de-sch%C3%BCler-vidal",
      },
    ],
    img: IMG01,
  },
];

console.log(CONTENT_CARDS[0].links.name01);

function App() {
  // const history = useHistory();

  // const handleRoute = () => {
  //   history.push("");
  // };

  

  return (
    <div className="app">
      <Navbar />
      <div className="div-card-extras">
        <div className="card-extras breakpoint-img">
          {CONTENT_CARDS.map((item, index) => {
            return (
              <div className="imagen-label">
                <img className="img-card card-images" src={item.img} />
                <div className="textos-flotantes">
                  <div>
                    <div className="texto-flotante upper-text">
                      <label>
                        <h3 className="subtitle">{item.title}</h3>
                      </label>
                    </div>
                  </div>

                  {item.links.map((links, index) => {
                    return (
                      <div className="texto-flotante-01">
                        <a href={links.link}>
                          <div className="texto-flotante down-text">
                            <label>
                              <Card.Text>{links.name}</Card.Text>
                            </label>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
