import avatar from "../img/avatar-100.png";
import "../styles/navbar.css";

export const Navbar = () => {
  return (
    <nav className="barra-superior">
      <div>
        <h1 className="title">Ada Schüler</h1>
      </div>
      <img className="avatar" src={avatar} width="80px" height="80px" />
      <div>
        <h3 className="subtitle">LINKS</h3>
      </div>
    </nav>
  );
};
